<template>
  <div class="outside-choose-condition mt-5">
    <v-form ref="caculationFormData">
      <div class="area-bei-monitoring mb-12">
        <div class="floor-area-part" :style="'width: ' + widthDataByText($t('casbee.label_total_floor_area_classification'), floorAreaList, '200') + 'px'">
          <p class="outside-lable">{{ $t('casbee.label_total_floor_area_classification') }}</p>
          <default-pull-down-menu
            v-model="floorArea"
            :items="floorAreaList"
            :initialValue="floorArea"
            placeHolderText="計算式を利用して原単位を追加"
            :width="'100%'"
            :height="'32px'"
            :isCasbeePulldown="true"
            :disabled="isViewMode"
            class="casbee-pulldown"
          />
        </div>
        <div class="bei-part">
          <p class="outside-lable">BEI</p>
          <casbee-input
            v-model="beiNumber"
            :value="beiNumber"
            :label="''"
            :disabled="isViewMode"
            :errorMessage="inputErrors.beiNumber"
            @removeError="inputErrors.beiNumber = ''"
          />
        </div>
        <div class="monitoring-part">
          <div class="outside-icon-help">
            <p class="outside-lable">{{ $t("casbee.label_efficient_operation") }}</p>
            <div
              class="helper-icon"
              @click="navigateToMonitoringScreen($event)"
            >
              <img
                :src="getSettingIcon('helper.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
            </div>
          </div>
          <div class="monitoring-width">
            <default-pull-down-menu
            v-model="level"
            :items="monitoringList"
            :initialValue="level"
            :width="'100%'"
            :boxWidth="'100%'"
            :height="'32px'"
            :disabled="isViewMode"
            placeHolderText="計算式を利用して原単位を追加"
            :isCasbeePulldown="true"
            class="casbee-pulldown"
          />
          </div>
        </div>
      </div>
      <div class="mb-12 energy-item">
        <div class="outside-icon-help">
          <p class="outside-lable">{{ $t("casbee.label_level_use_natural_energy") }}</p>
          <div
            class="helper-icon"
            @click="showModelNaturalEnergyLevel($event)"
          >
            <img
              :src="getSettingIcon('helper.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
          </div>
        </div>
        <p class="outside-description">{{ $t("casbee.description_level_use_natural_energy") }}</p>
        
        <div class="pulldown-width">
          <default-pull-down-menu
          v-model="naturalEnergyLevel"
          :items="naturalEnergyLevelList"
          :initialValue="naturalEnergyLevel"
          :width="'100%'"
          :boxWidth="'100%'"
          :height="'32px'"
          :isCasbeePulldown="true"
          placeHolderText="計算式を利用して原単位を追加"
          class="casbee-pulldown"
          :disabled="isViewMode"
        />
        </div>
      </div>
      <div class="power-item">
        <p class="outside-lable">{{ $t("casbee.label_electricity_emission") }}</p>
        <p class="outside-description">{{ $t("casbee.description_change_emission_factor") }}</p>
        <div class="number-power">
          <casbee-input
            v-model="numberOfPower"
            :value="numberOfPower"
            :label="'t-CO2/kWh'"
            :disabled="isViewMode"
            :errorMessage="inputErrors.numberOfPower"
            @removeError="inputErrors.numberOfPower = ''"
          />
        </div>
      </div>
    </v-form>
    <common-button
      v-if="!isViewMode"
      class="casbee-btn mt-12"
      v-ripple="false"
      type="colored"
      :isDisable="isViewMode"
      :label="$t('casbee.button_calc_value_inputed')"
      @action="handlerCalculationData()"
    />
    <energy-level-popup :dialog = "dialogEnergy" @close = "dialogEnergy = false" />
  </div>
</template>
<script>
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CommonButton from "@/components/utils/button.vue";
import { CASBEE_TYPE } from '@/constants/casbee/';
import { PERPOSE_USING_BUILDING, DATA_FOR_CALCULATION, VALUE_CALCULATION_DEFAULT } from '@/constants/casbee/outside';
import { FLOOR_AREA, MONITORING, NATURAL_ENERGY_LEVEL_IN_SCHOOL, NATURAL_ENERGY_LEVEL_OUT_SCHOOL, ENERGY_CONSUMPTION, NATURAL_ENERGY_LEVEL, MONITORING_VALUE } from '@/constants/casbee/outsideChooseCondition';
import { validateInputFieldCasbee } from '@/utils/validate';
import EnergyLevelPopup from '@/components/dialogs/example-evalued-dialog.vue';
import CasbeeInput from '@/components/casbee/input';
import {getWidthByTextContent} from '@/utils/calcTextWidth'
const math = require('mathjs');

export default {
  components: { DefaultPullDownMenu, CommonButton, CasbeeInput, EnergyLevelPopup },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    perposeUsingBuildingModel: {
      type: String,
      default: () => '',
    }
  },
  data() {
    return {
      floorArea: FLOOR_AREA.TYPE_1,
      level: MONITORING.LEVEL_BLANK,
      beiNumber: 1,
      numberOfPower: 0.00043,
      naturalEnergyLevel: NATURAL_ENERGY_LEVEL_OUT_SCHOOL.LEVEL_1,
      naturalEnergyLevelList: this.formatDataToList(NATURAL_ENERGY_LEVEL_OUT_SCHOOL),
      perposeUsingBuildingList: this.formatDataToList(PERPOSE_USING_BUILDING),
      floorAreaList: this.formatDataToList(FLOOR_AREA),
      monitoringList: this.formatDataToList(MONITORING),
      casbeeData: {
        total: null,
        source: '',
        patternType: CASBEE_TYPE.ARCHITECTURE_OUTSIDE_CHOOSE_CONDITION,
        label: this.$t("casbee.description_emission_co2_per_m2"),
        labelSource: this.$t("casbee.description_source"),
      },
      inputErrors: {
        beiNumber: '',
        numberOfPower: '',
      },
      dialogEnergy: false,
    };
  },
  mounted() {
    this.prepareDetailData();
    this.setNatureData(this.perposeUsingBuildingModel);
  },
  watch: {
    perposeUsingBuildingModel(value) {
      this.setNatureData(value);
  }
  },
  computed: {
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    isViewMode() {
      return this.detailData.hasOwnProperty('dataDetail');
    },
  },
  methods: {
    widthLabelByText(string) {
      return getWidthByTextContent(string) + 'px';
    },
    widthDataByText(string, dataList, widthJa) {
      if(this.$i18n.locale === 'ja') {
        return widthJa;
      }
      let textLabel = parseFloat(this.widthLabelByText(string));
      let textPulldown = parseFloat(this.widthPulldownByText(dataList));
      return textLabel < textPulldown ? textPulldown : textLabel;
    },
    widthPulldownByText(dataList, widthJa) {
      if(this.$i18n.locale === 'ja') {
        return widthJa;
      }
      let widthListPulldowns = [];
      dataList.forEach(item => {
        widthListPulldowns.push(getWidthByTextContent(item.name));
      });
      return Math.max(...widthListPulldowns) + 'px';
    },
    setNatureData(value) {
        if([PERPOSE_USING_BUILDING.JUNIOR_HIGHT_SCHOOL_HOKKAIDO, PERPOSE_USING_BUILDING.JUNIOR_HIGHT_SCHOOL_OTHER].includes(value)) {
          this.naturalEnergyLevelList = this.formatDataToList(NATURAL_ENERGY_LEVEL_IN_SCHOOL);
          if(!this.isViewMode){
            this.naturalEnergyLevel =  NATURAL_ENERGY_LEVEL_IN_SCHOOL.LEVEL_1;
          }
        } else {
          this.naturalEnergyLevelList = this.formatDataToList(NATURAL_ENERGY_LEVEL_OUT_SCHOOL);
          if(!this.isViewMode){
            this.naturalEnergyLevel =  NATURAL_ENERGY_LEVEL_OUT_SCHOOL.LEVEL_1;
          }
        }
    },
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
      if (this.isValidData) {
        // A 一次エネルギー消費量/lượng tiêu thụ Năng lượng sơ cấp」
        let energyConsumption = ENERGY_CONSUMPTION[this.perposeUsingBuildingModel].find(item => item.type === this.floorArea).value;
        
        let newBeiNumber = math.bignumber(this.beiNumber);
        let newNumberOfPower = math.bignumber(this.numberOfPower);
        
        let beiNumberInEnergy = math.multiply(energyConsumption, newBeiNumber);
        let levelEnergy = math.subtract(beiNumberInEnergy, NATURAL_ENERGY_LEVEL.find((item) => item.type === this.naturalEnergyLevel).value);
        let energyConsumptionLevel1 = math.multiply (levelEnergy , MONITORING_VALUE.find((item) => item.type === this.level).value);

        // B: 電気/điện, ガス/ga, その他/other
        let dataToCalculation = DATA_FOR_CALCULATION.find((item) => item.type === this.perposeUsingBuildingModel);

        let numberOfPowerPart = math.divide(newNumberOfPower, VALUE_CALCULATION_DEFAULT.CALC_ELECTRIC);
        let numberOfPowerPartDivided = math.multiply(numberOfPowerPart, VALUE_CALCULATION_DEFAULT.CALC_TOTAL);

        let totalElectric = math.multiply(dataToCalculation.electricity / 100, energyConsumptionLevel1, numberOfPowerPartDivided);
        let totalGas = math.multiply(dataToCalculation.gas / 100, energyConsumptionLevel1, VALUE_CALCULATION_DEFAULT.CALC_GAS);
        let totalOthers = math.multiply(dataToCalculation.others / 100, energyConsumptionLevel1, VALUE_CALCULATION_DEFAULT.CALC_OTHERS);

        // totalResult
        let totalResult = math.add(totalElectric, totalGas, totalOthers);
        totalResult = math.divide(totalResult, VALUE_CALCULATION_DEFAULT.CALC_TOTAL).toFixed(4).toString();
        const perposeUsingBuilding = this.perposeUsingBuildingModel;
        const floorArea = this.floorArea;
        const beiNumber = this.beiNumber;
        const naturalEnergyLevel = this.naturalEnergyLevel;
        const level = this.level
        let source = this.$t("casbee.input_field_source_text_outside_choose_condition", { perposeUsingBuilding, floorArea, beiNumber, naturalEnergyLevel, level});
        
        let casbeeData = {
          ... this.casbeeData,
          total: totalResult,
          source: source,
          content_json: {
            perposeUsingBuildingModel: this.perposeUsingBuildingModel,
            floorArea: this.floorArea,
            level: this.level,
            naturalEnergyLevel: this.naturalEnergyLevel,
            beiNumber: this.beiNumber,
            numberOfPower: this.numberOfPower
          }
        }
        this.$emit('onHandlerCalculationData', casbeeData);
      } else {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
      }
    },
    formatDataToList(data) {
      return Object.keys(data).map((item) => {
        return { name: data[item], value: data[item] };
      });
    },
    navigateToMonitoringScreen() {
      const routeData = this.$router.resolve({ name: 'ProductsminitoringCasbee' });
      window.open(routeData.href, '_blank');
    },
    getSettingIcon(image) {
      return require(`@/assets/icons/casbee/${image}`);
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper.svg");
      } else {
        event.target.src = this.getSettingIcon("active.svg");
      }
    },
    showModelNaturalEnergyLevel() {
      this.dialogEnergy = true;
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>

.outside-choose-condition {

  .outside-lable {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
  .outside-description {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 15px;
    color: $monoBlack;
    line-height: 28px;
    letter-spacing: 0.05em;
  }
  .area-bei-monitoring {
    .bei-part {
      margin-top: 48px;
    }
    .monitoring-part {
      margin-top: 48px;
      .monitoring-width {
          width: 160px;
        }
    }
  }
  .outside-icon-help {
    display: flex;
    align-items: center;
    .helper-icon {
      margin-left: 5px;
      padding-bottom: 4px;
      cursor: pointer;
    }
  }
  .number-power {
    display: flex;
    align-items: center;
    gap: 20px;
    &-input {
      width: 80px;
      max-width: 80px;
    }
    &_unit {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $monoBlack;
    }
  }
  .casbee-btn {
    width: 100%;
  }
  ::v-deep .pull-down-adjust .selection-box-bridge .input-box {
    width: calc(100vw - 50px);
  }
}

@include desktop {
  .outside-choose-condition {
    .outside-lable {
      margin-bottom: 12px;
      font-size: 18px;
    }
    .outside-description {
      margin-bottom: 12px;
      line-height: 20px;
      letter-spacing: unset;
    }
    .area-bei-monitoring {
      margin-top: 48px;
      display: flex;
      gap: 40px;
      .bei-part {
        margin-top: 0px;
      }
      .floor-area-part {
        width: 200px;
      }
      .monitoring-part {
        margin-top: 0px;
        .monitoring-width {
          width: 120px;
        }
      }
    }
    .outside-icon-help {
      .helper-icon {
        padding-bottom: 8px;
      }
    }
      .energy-item {
        .pulldown-width {
          width: 260px;
        }
    }
    .casbee-btn {
      // width: 240px;
      width: max-content;
    }
  }
}

</style>